import urlcat from 'urlcat';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import { isBoolean } from 'lodash';
import { getCooeeApiUrl } from '../utils/api';
import { getHostEnv } from '../utils/api';
import axios from '../utils/axios';
import logger from '../utils/logger';
import { logoutUser, updateUserInfo } from '../store/actions/appActions';

const useGetUserInfo = (props) => {
  const { execute = false } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(-1);
  const baseUrl = getCooeeApiUrl(getHostEnv());
  const {
    app: { user, userInfo },
  }: any = useSelector((state) => state);
  const { accessToken, tokenType } = user;
  const dispatch = useDispatch();

  const getUserInfo = async () => {
    if (!isLoading) {
      if (accessToken && (execute || autoRefresh > 0)) {
        setIsLoading(true);

        let response: AxiosResponse;
        try {
          response = await axios.get(urlcat(baseUrl, 'user/info'), {
            headers: { Authorization: `${tokenType} ${accessToken}` },
          });
          const { data, status } = response;
          if (status === 200) {
            await logger.debug(data?.data);
            dispatch(updateUserInfo({ ...data?.data }));
            const { emailVerified } = camelcaseKeys(data?.data);
            if (
              isBoolean(emailVerified) &&
              !emailVerified &&
              autoRefresh <= 0
            ) {
              setAutoRefresh(1);
            } else {
              setAutoRefresh(-1);
            }
          } else logger.warn('Error getting user info').finally();
        } catch (error) {
          if (error?.response) {
            const { request, ...errorResponse } = error?.response;

            response = errorResponse;
            logger.warn(response).finally();
          } else logger.error(error).finally();
          setAutoRefresh(-1);
        }

        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserInfo().catch();
  }, [execute]);

  useEffect(() => {
    if (autoRefresh > 0) {
      setTimeout(() => {
        // Prevent multiple getInfo at the same time
        if (!isLoading) getUserInfo().catch();
        setAutoRefresh(autoRefresh + 1);
      }, 20000);
    }
  }, [autoRefresh]);

  // useEffect(() => {
  //   if (isAutoRefreshEnabled) {
  //     setAutoRefresh(1);
  //   } else {
  //     setAutoRefresh(-1);
  //   }
  // }, [isAutoRefreshEnabled]);

  return { user, userInfo: camelcaseKeys(userInfo), getUserInfo };
};

export default useGetUserInfo;
