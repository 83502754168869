import urlcat from 'urlcat';
import { useQuery } from 'react-query';
import camelcaseKeys from 'camelcase-keys';
import { getCooeeApiUrl } from '../utils/api';
import { getHostEnv } from '../utils/api';
import axios from '../utils/axios';

const getHotTopics = async (props) => {
  const apiUrl = getCooeeApiUrl(getHostEnv());

  let result = {};

  result = (await axios.get(urlcat(apiUrl, `topics/hot`))).data;

  return camelcaseKeys(result?.data);
};

const useGetHotRightNowTopics = (props) =>
  useQuery(['hotTopics', props], () => getHotTopics(props));

export default useGetHotRightNowTopics;
