import React, { useEffect, useMemo, useState } from 'react';
import urlcat from 'urlcat';
import { intersectionBy, isArray, isEmpty, isString, sortBy } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { useDispatch, useSelector } from 'react-redux';
import {
  DetailedProfileView,
  MiniProfileView,
} from '../components/ProfileViews';
import { BasicProfile, DetailedProfile } from '../interfaces';
import { MiniProfileViewCollection } from '../components/MiniProfileViewCollection';
import { COLOURS } from '../styles/Constants';
import logger from '../utils/logger';
import { getHostEnv } from '../utils/api';
import axios from '../utils/axios';
import useGetUserInfo from '../hooks/useGetUserInfo';
import { getLocalApiUrl } from '../utils/api';
import useGetHotRightNowTopics from '../hooks/useGetHotRightNowTopics';
import { updateTopics } from '../store/actions/topicActions';
import { filterTopics } from '../utils/common';
import useUserInfo from '../hooks/useUserInfo';
import parse from 'html-react-parser';

const backgroundPhotoStaticPath = '/images/profilePicture.jpg';
const dummydata: DetailedProfile = {
  id: 7,
  firstName: 'Harry',
  lastName: 'Potter',
  title: 'Quidditch Seeker',
  conversation: 'Test Conversation',
  verified: true,
  profilePictureUrl: 'ss',
  coverPictureUrl: 'ss',
  numConversations: 99,
  description: 'Best wizard in the UK is here to teach you some magic',
  profileBannerUrl: 'huuu',
  bookingUrl: 'huuu',
};

const Index = (props): JSX.Element => {
  const dispatch = useDispatch();
  const { featuredProfiles } = props;
  const { data, isFetching, isFetched } = useGetHotRightNowTopics({});
  const { userInfo } = useUserInfo();

  useGetUserInfo({ execute: true });

  // @ts-ignore
  const { topics } = useSelector((state) => state.topics);

  useEffect(() => {
    if (
      isFetched &&
      data.topics &&
      isArray(data.topics) &&
      data.topics.length > 0
    ) {
      dispatch(updateTopics(filterTopics(data.topics, userInfo)));
    }

    // Code to test CORS
    // (async () => {
    //   try {
    //     const { data } = await axios.get(
    //       'https://static.oncooee.com/legal/generalterms.html'
    //     );
    //   } catch (err) {
    //     await logger.error(err);
    //   }
    // })();
  }, [data]);

  const memoizedTopics = useMemo(() => {
    if (
      isFetched &&
      data.topics &&
      isArray(data.topics) &&
      data.topics.length > 0 &&
      isArray(topics)
    ) {
      return sortBy(intersectionBy(topics, data.topics, 'uuid'), 'sortOrder');
    }
    return [];
  }, [topics]);

  const [panels, setPanels] = useState([0, 1, 2, 3, 4]);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${
            featuredProfiles[0].coverPictureUrl || backgroundPhotoStaticPath
          })`,
          backgroundPosition: 'top',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: 700,
          maxWidth: '100%',
          width: '100vw',
          color: '#f5f5f5',
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -5,
        }}
      >
        <div
          style={{
            backgroundImage:
              'linear-gradient(270deg, rgba(0, 0, 0, 0) 90%, rgb(0, 0, 0) 100vw), linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 650px, rgba(0,0,0,1) 700px, rgba(0,0,0,1) 100%)',
            // backgroundColor: COLOURS.darkGreyTransparent,
            width: '100vw',
            height: '100%',
            // overflow: 'auto',
            // paddingTop: 150,
          }}
        />
      </div>
      <div style={{ marginLeft: '5%', minHeight: 450, paddingTop: 200 }}>
        <DetailedProfileView {...(featuredProfiles[0] || dummydata)} />
      </div>
      <div style={{ marginTop: '5%' }} />
      {isFetched && (
        <MiniProfileViewCollection
          style={{ marginTop: '2%' }}
          title="Hot Right Now"
          topics={memoizedTopics}
        />
      )}
      {isFetching && <h3>Loading...</h3>}
    </>

    // </div>
  );
};

export async function getServerSideProps(ctx) {
  const baseUrl = getLocalApiUrl(getHostEnv());

  try {
    // eslint-disable-next-line prefer-const
    const url = urlcat(baseUrl, `public/campaign`);
    const response = await fetch(url);
    let data = await response.json();

    if (!isEmpty(response)) {
      data = camelcaseKeys(data);
    }

    if (data?.data)
      return {
        props: {
          featuredProfiles: data.data,
        },
      };
  } catch (error) {
    await logger.error(error);
  }

  return {
    notFound: true,
  };
}

export default Index;
